import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import { useState } from "react";
import Box from "@mui/joy/Box";
import { addNewWord, updateTranslation } from "../../services/services";

export default function InputFields({
  isFromEdit,
  selectedTranslation,
  setOpenModal,
  setResponse,
  setIsOpen,
  fetchAllTranslations,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const setData = async (gr, rmgs, rmgn) => {
    try {
      setIsLoading(true);
      const result = isFromEdit
        ? await updateTranslation(selectedTranslation.id, gr, rmgs, rmgn)
        : await addNewWord(gr, rmgs, rmgn);
      if (result.statusCode === 1) {
        setResponse({
          message: isFromEdit
            ? "Αλλάχτηκε με επιτυχία"
            : "Προστέθηκε με επιτυχία",
          error: false,
        });
        return true;
      } else if (result.statusCode === 2) {
        setResponse({ message: "Αυτή η λέξη υπάρχει ήδη", error: true });
      }
    } catch (e) {
      console.log(e);
      setResponse({
        message: "Υπήρχε κάποιο πρόβλημα παρακαλώ δοκιμάστε ξανά",
        error: true,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(true);
      if (isFromEdit) {
        setOpenModal(false);
      }
      await fetchAllTranslations();
    }
  };

  return (
    <Box
      component="main"
      sx={{
        my: "auto",
        py: 2,
        pb: 5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: 400,
        maxWidth: "100%",
        mx: "auto",
        borderRadius: "sm",
        "& form": {
          display: "flex",
          flexDirection: "column",
          gap: 2,
        },
        [`& .${formLabelClasses.asterisk}`]: {
          visibility: "hidden",
        },
      }}
    >
      <Stack gap={4} sx={{ width: "100%" }}>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            const formElements = event.currentTarget.elements;
            const e = event.currentTarget;
            const result = await setData(
              formElements?.gr?.value?.toLowerCase(),
              formElements?.rmgs?.value?.toLowerCase(),
              formElements?.rmgn?.value?.toLowerCase()
            );
            if (result) {
              e.reset();
            }
          }}
        >
          <FormControl required>
            <FormLabel>Ελληνικά</FormLabel>
            <Input
              defaultValue={selectedTranslation ? selectedTranslation.gr : ""}
              type={"text"}
              name="gr"
            />
          </FormControl>{" "}
          <FormControl required>
            <FormLabel>Ρομανί Νότια</FormLabel>
            <Input
              defaultValue={selectedTranslation ? selectedTranslation.rmgs : ""}
              type={"text"}
              name="rmgs"
            />
          </FormControl>{" "}
          <FormControl required>
            <FormLabel>Ρομανί Βόρεια</FormLabel>
            <Input
              defaultValue={selectedTranslation ? selectedTranslation.rmgn : ""}
              type={"text"}
              name="rmgn"
            />
          </FormControl>
          <Stack gap={2} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></Box>
            <Button loading={isLoading} type="submit" fullWidth>
              {isFromEdit ? "Αλλαγή" : "Προσθήκη"}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}
