import * as React from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import ModalDialog from "@mui/joy/ModalDialog";
import { DialogActions, DialogContent, DialogTitle } from "@mui/joy";
import Divider from "@mui/joy/Divider";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import InputFields from "../../pages/dashboardComponents/inputFields";

function MyModal({
  open,
  setOpen,
  selectedTranslation,
  setOpenSnackbar,
  setResponseData,
  fetchAllTranslations,
}) {
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingX: 2,
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Επεξεργασία
          </Typography>

          <InputFields
            setIsOpen={setOpenSnackbar}
            setResponse={setResponseData}
            selectedTranslation={selectedTranslation}
            isFromEdit
            setOpenModal={setOpen}
            fetchAllTranslations={fetchAllTranslations}
          />
        </Sheet>
      </Modal>
    </>
  );
}

function AlertDialogModal({ open, setOpen, deleteTranslationData }) {
  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Επιβεβαίωση διαγραφής
          </DialogTitle>
          <Divider />
          <DialogContent>
            Είσαι σίγουρος ότι θέλεις να διαγράψεις;
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                setOpen(false);
                deleteTranslationData();
              }}
            >
              Διαγραφή
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Ακύρωση
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}

export { MyModal, AlertDialogModal };
