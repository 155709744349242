import React, { useState } from "react";
import { Box, Grid } from "@mui/joy";
import TranslationInputs from "./components/TranslationInputs";
import TranslationHeader from "./components/TranslationHeader";
import TranslationDisplayResult from "./components/TranslationDisplayResult";

const Translation = () => {
  const [translatedWord, setTranslatedWord] = useState("");
  const [text, setText] = useState("");

  const [fromLanguage, setFromLanguage] = useState({
    value: "Ελληνικά",
    type: "gr",
  });
  const [toLanguage, setToLanguage] = useState({
    value: "Ρομανί Βόρεια",
    type: "rmgn",
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: "1300px",
        margin: "auto",
      }}
    >
      <Grid
        container
        columns={7}
        sx={{ flexGrow: 1, padding: 1 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={16} justifyContent="center">
          <TranslationHeader
            fromLanguage={fromLanguage}
            setFromLanguage={setFromLanguage}
            toLanguage={toLanguage}
            setToLanguage={setToLanguage}
            setText={setText}
            text={text}
            setTranslatedWord={setTranslatedWord}
            translatedWord={translatedWord}
          />
        </Grid>
        <Grid xs={16} sm={8} md={3} sx={{ padding: 1 }}>
          <TranslationInputs
            toLanguage={toLanguage}
            fromLanguage={fromLanguage}
            setTranslatedWord={setTranslatedWord}
            setIsLoading={setIsLoading}
            text={text}
            setText={setText}
          />
        </Grid>
        <Grid xs={16} sm={8} md={3} sx={{ padding: 1 }}>
          <TranslationDisplayResult
            translatedWord={translatedWord}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Translation;
