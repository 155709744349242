import { apiUrl } from "./constants";

export const request = async (url, payload, headers, typeOfRequest) => {
  let typeOfRequestBeUsed;
  let headersToBeUsed;
  let accessToken = JSON?.parse(localStorage.getItem("accessToken"));

  if (!typeOfRequest) {
    typeOfRequestBeUsed = "GET";
  } else {
    typeOfRequestBeUsed = typeOfRequest;
  }

  if (!headers && !accessToken) {
    headersToBeUsed = {
      "Content-Type": "application/json",
    };
  }
  if (!headers && accessToken) {
    headersToBeUsed = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
  }
  if (headers && accessToken) {
    headersToBeUsed = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }
  try {
    const response = await fetch(`${apiUrl}${url}`, {
      method: typeOfRequestBeUsed,
      headers: headersToBeUsed,
      body: payload ? JSON.stringify(payload) : null,
    });
    return response.json();
  } catch (e) {
    console.log(e);
    return { message: e.message };
  }
};
