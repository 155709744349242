import * as React from "react";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useEffect, useState } from "react";
import { CircularProgress, Grid, iconButtonClasses } from "@mui/joy";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../../pages/components/Loader";
import Button from "@mui/joy/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import FormLabel from "@mui/joy/FormLabel";

function RowMenu({ translationData, handleEditableTranslation }) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu placement="left-start">
        <MenuItem
          onClick={() => {
            handleEditableTranslation(translationData, 1);
          }}
        >
          Επεξεργασία
        </MenuItem>
        <Divider />
        <MenuItem
          color="danger"
          onClick={() => {
            handleEditableTranslation(translationData, 0);
          }}
        >
          Διαγραφή{" "}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

function MyTable({ allTranslations, handleEditableTranslation, isFetching }) {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredTranslations, setFilteredTranslations] = useState([]);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    createPages();
  }, [filteredTranslations, rowsPerPage]);

  useEffect(() => {
    if (searchText !== "" && allTranslations.length) {
      setIsLoading(true);
      const delayDebounceFn = setTimeout(() => {
        filterData();
      }, 1000);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    } else {
      setFilteredTranslations([...allTranslations]);
      setIsLoading(false);
    }
  }, [searchText, allTranslations]);

  function splitArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  const filterData = () => {
    setIsLoading(false);
    let filter = allTranslations.filter(
      (translation) =>
        normalizeGreek(translation.gr).includes(normalizeGreek(searchText)) ||
        normalizeGreek(translation.rmgs).includes(normalizeGreek(searchText)) ||
        normalizeGreek(translation.rmgn).includes(normalizeGreek(searchText))
    );
    setFilteredTranslations(filter);
  };

  const normalizeGreek = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const createPages = () => {
    let pages = [];
    const resultArrays = splitArray(filteredTranslations, rowsPerPage);
    resultArrays.forEach(function (sublist, index) {
      // console.log(`Υπολίστα ${index + 1}:`, sublist);
      pages.push(sublist);
    });
    setTableData(pages);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const changePageByButton = (page) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue, 10));
    setPage(0);
  };

  function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const getLabelDisplayedRowsTo = () => {
    if (allTranslations.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? allTranslations.length
      : Math.min(allTranslations.length, (page + 1) * rowsPerPage);
  };
  return (
    <Box
      sx={{
        backgroundColor: "var(--joy-palette-background-level1)",
        borderRadius: 5,
        p: 1,
        display: "grid",
      }}
    >
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          px: 1,
          display: { xs: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <Input
            value={searchText}
            type={"text"}
            name="search"
            placeholder="Αναζήτηση"
            onChange={(e) => setSearchText(e.target.value)}
            startDecorator={
              isLoading ? <CircularProgress size="sm" /> : <SearchIcon />
            }
            endDecorator={
              searchText !== "" && (
                <IconButton onClick={() => setSearchText("")}>
                  <ClearIcon />
                </IconButton>
              )
            }
          />
        </FormControl>
      </Box>
      <Typography p={2} level={"h4"} fontSize={14}>
        Σύνολο λέξεων: {filteredTranslations.length}
      </Typography>

      <Sheet
        className="OrderTableContainer"
        variant="soft"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        {isFetching && <Loader />}
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level2)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 20, padding: "12px 6px" }}>ID</th>
              <th style={{ width: 100, padding: "12px 6px" }}>Ελληνικά</th>
              <th style={{ width: 100, padding: "12px 6px" }}>Ρομανί Νότια</th>
              <th style={{ width: 100, padding: "12px 6px" }}>Ρομανί Βόρεια</th>
              <th style={{ width: 100, padding: "12px 6px" }}> </th>
            </tr>
          </thead>
          <tbody>
            {tableData[page]?.map((translation, index) => (
              <tr key={translation.id}>
                <td>
                  <Typography level="body-xs">{translation.id}</Typography>
                </td>
                <td>
                  <Typography level="body-sm">{translation.gr}</Typography>
                </td>
                <td>
                  <Typography level="body-sm">{translation.rmgs}</Typography>
                </td>
                <td>
                  <Typography level="body-sm">{translation.rmgn}</Typography>
                </td>
                <td>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: " flex-end",
                    }}
                  >
                    <RowMenu
                      handleEditableTranslation={handleEditableTranslation}
                      translationData={translation}
                    />
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        sx={{
          // pt: 1,
          // gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: "flex",
          justifyContent: { xs: "space-between", md: "flex-end" },
          alignItems: "center",
          borderTop: 1,
          borderColor: "rgba(199,199,199,0.92)",
        }}
        backgroundColor={"primary"}
      >
        <FormControl size="sm" sx={{ marginX: { md: 2 }, paddingY: { xs: 2 } }}>
          <Select onChange={handleChangeRowsPerPage} defaultValue={rowsPerPage}>
            <Option value={20}>20</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </FormControl>
        <Typography
          alignItems={"center"}
          textAlign="center"
          sx={{ minWidth: 80, fontSize: 14, marginX: { xs: 1, md: 1 } }}
        >
          {labelDisplayedRows({
            from: allTranslations.length === 0 ? 0 : page * rowsPerPage + 1,
            to: getLabelDisplayedRowsTo(),
            count: allTranslations.length === -1 ? -1 : allTranslations.length,
          })}
        </Typography>
        <Grid display={"flex"} alignItems={"center"} gap={1}>
          <Button
            disabled={0 === page}
            size="sm"
            variant="outlined"
            color="primary"
            onClick={previousPage}
          >
            <KeyboardArrowLeftIcon />
          </Button>

          <Button
            disabled={
              tableData.length === page + 1 &&
              filteredTranslations.length > rowsPerPage - 1
            }
            size="sm"
            variant="outlined"
            color="primary"
            onClick={nextPage}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
export default MyTable;
