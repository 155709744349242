import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
      }}
    >
      <Sheet variant={"soft"} sx={{ padding: 1 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SettingsOutlinedIcon
            onClick={() => navigate("/login")}
            size={"sm"}
            sx={{ cursor: "pointer" }}
          />
          <Typography level={"body-xs"}>
            © {new Date().getFullYear()}
          </Typography>
        </Box>
      </Sheet>
    </Box>
  );
};

export default Footer;
