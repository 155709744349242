import React, { useState } from "react";
import Box from "@mui/joy/Box";
import BasicAutocomplete from "./LanguageSelect";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import IconButton from "@mui/joy/IconButton";
import { Grid } from "@mui/joy";
import LanguageSelect from "./LanguageSelect";

const TranslationHeader = ({
  fromLanguage,
  setFromLanguage,
  toLanguage,
  setToLanguage,
  setText,
  text,
  setTranslatedWord,
  translatedWord,
}) => {
  const [reverted, setReverted] = useState(false);
  const switchLanguages = () => {
    setFromLanguage(toLanguage);
    setToLanguage(fromLanguage);
    setReverted(!reverted);
    if (translatedWord !== "Δεν βρέθηκε η λέξη που ψάχνετε" && text !== "") {
      setTranslatedWord(text);
      setText(translatedWord);
    } else {
      setText("");
    }
  };

  return (
    <Box>
      <Grid container columns={9} sx={{ justifyContent: "center", mb: 1 }}>
        <Grid xs={10} sm={3} md={2} sx={{ textAlign: "center" }}>
          <LanguageSelect
            fromLanguage={fromLanguage}
            setFromLanguage={setFromLanguage}
            toLanguage={toLanguage}
            setToLanguage={setToLanguage}
            reverted={reverted}
            setReverted={setReverted}
          />
        </Grid>

        <Grid xs={10} sm={1} md={1} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={switchLanguages}
            color="primary"
            aria-label="Revert Language"
          >
            <CodeRoundedIcon />
          </IconButton>
        </Grid>

        <Grid xs={10} sm={3} md={2} sx={{ textAlign: "center" }}>
          <LanguageSelect
            fromLanguage={fromLanguage}
            setFromLanguage={setFromLanguage}
            toLanguage={toLanguage}
            setToLanguage={setToLanguage}
            isForTranslatedText
            reverted={reverted}
            setReverted={setReverted}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TranslationHeader;
