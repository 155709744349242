import * as React from "react";
import Snackbar from "@mui/joy/Snackbar";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
export default function SnackbarComponent({ open, setOpen, message, error }) {
  return (
    <Snackbar
      autoHideDuration={3000}
      variant="soft"
      color={error ? "danger" : "success"}
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      startDecorator={
        !error ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />
      }
    >
      {message}
    </Snackbar>
  );
}
