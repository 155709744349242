import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import theme from "../theme";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginService } from "../services/services";
import SnackbarComponent from "../components/Snackbar/SnackbarComponent";

function Login() {
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("LoggedIn");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState({ message: "", error: false });
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, [loggedIn]);

  const loginFn = async (username, password) => {
    try {
      setIsLoading(true);
      const result = await loginService(username, password);
      if (!result.error) {
        localStorage.setItem("LoggedIn", JSON.stringify(true));
        navigate("/dashboard");
      } else {
        setIsOpen(true);
        setResponse({ message: "Λάθος κωδικό πρόσβασης ή email", error: true });
      }
    } catch (e) {
      console.log(e);
      setIsOpen(true);
      setResponse({
        message: "Κάτι πήγε στραβά παρακαλώ δοκιμάστε ξανά",
        error: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange theme={theme}>
      <CssBaseline />
      <Header />
      <Box
        sx={(theme) => ({
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "center",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "90dvh",
            maxWidth: "100%",
            px: 2,
          }}
        >
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 1,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
              marginTop: 1,
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography level="h3">Σύνδεση</Typography>
              </Stack>
            </Stack>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  await loginFn(
                    formElements.email.value,
                    formElements.password.value
                  );
                }}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  ></Box>
                  <Button loading={isLoading} type="submit" fullWidth>
                    Σύνδεση
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </Box>
      </Box>
      <SnackbarComponent
        open={isOpen}
        setOpen={setIsOpen}
        message={response.message}
        error={response.error}
      />
      <Footer />
    </CssVarsProvider>
  );
}
export default Login;
