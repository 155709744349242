import * as React from "react";
import Autocomplete from "@mui/joy/Autocomplete";
import {
  Dropdown,
  FormControl,
  Menu,
  MenuButton,
  MenuItem,
  Select,
  selectClasses,
} from "@mui/joy";
import { useEffect, useState } from "react";
import Option from "@mui/joy/Option";
import { KeyboardArrowDown } from "@mui/icons-material";

const languages = [
  {
    value: "Ελληνικά",
    type: "gr",
  },
  {
    value: "Ρομανί Νότια",
    type: "rmgs",
  },
  {
    value: "Ρομανί Βόρεια",
    type: "rmgn",
  },
];

function LanguageSelect({
  fromLanguage,
  setFromLanguage,
  toLanguage,
  setToLanguage,
  isForTranslatedText,
  reverted,
  setReverted,
}) {
  const [filteredLanguages, setFilteredLanguages] = useState(languages);

  useEffect(() => {
    if (isForTranslatedText) {
      const newLgList = languages.filter(
        (lg) => lg?.value !== toLanguage?.value
      );
      setFilteredLanguages(newLgList);
    } else {
      const newLgList = languages.filter(
        (lg) => lg?.value !== fromLanguage?.value
      );
      setFilteredLanguages(newLgList);
    }
  }, [reverted]);

  const filterLg = (newData) => {
    if (newData) {
      if (isForTranslatedText) {
        setToLanguage(newData);
        const newLgList = languages.filter(
          (lg) => lg?.value !== newData?.value
        );
        setFilteredLanguages(newLgList);
        if (newData?.value === fromLanguage?.value) {
          setFromLanguage(toLanguage);
          setReverted(!reverted);
        }
      } else {
        setFromLanguage(newData);
        const newLgList = languages.filter(
          (lg) => lg?.value !== newData?.value
        );
        setFilteredLanguages(newLgList);
        if (newData?.value === toLanguage?.value) {
          setToLanguage(fromLanguage);
          setReverted(!reverted);
        }
      }
    }
  };
  return (
    <Dropdown>
      <MenuButton
        sx={{
          minWidth: 180,
        }}
        endDecorator={<KeyboardArrowDown size={"sm"} />}
      >
        {isForTranslatedText ? toLanguage.value : fromLanguage.value}
      </MenuButton>
      <Menu
        size={"lg"}
        sx={{
          minWidth: 180,
        }}
      >
        {filteredLanguages?.map((item, index) => (
          <MenuItem
            key={index}
            value={item?.value}
            onClick={() => filterLg(item)}
          >
            {item?.value}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
export default LanguageSelect;
