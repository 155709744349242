import "./App.css";
import { CssVarsProvider } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import "@fontsource/inter";
import Translation from "./pages/Translation";
import Header from "./components/Header/Header";
import theme from "./theme";
import Box from "@mui/joy/Box";
import { CssBaseline } from "@mui/joy";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          width: "100%",
          minHeight: "100dvh",
        }}
      >
        <Header />
        <Translation />
      </Box>
      <Footer />
    </CssVarsProvider>
  );
}

export default App;
