import * as React from "react";
import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import List from "@mui/joy/List";
import ListItemButton from "@mui/joy/ListItemButton";
import ModalClose from "@mui/joy/ModalClose";
import { useNavigate } from "react-router-dom";
import { ListItemDecorator } from "@mui/joy";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import LogoutIcon from "@mui/icons-material/Logout";
function DrawerMenu({ open, setOpen }) {
  const navigate = useNavigate();

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            ml: "auto",
            mt: 1,
            mr: 2,
          }}
        >
          <ModalClose id="close-icon" sx={{ position: "initial" }} />
        </Box>
        <List
          size="lg"
          component="nav"
          sx={{
            flex: "none",
            fontSize: "lg",
            "& > div": { justifyContent: "left" },
          }}
        >
          <ListItemButton
            onClick={() => {
              navigate("/");
              setOpen(false);
            }}
          >
            <ListItemDecorator>
              <GTranslateIcon />
            </ListItemDecorator>
            Μετάφραση
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              navigate("/dashboard");
              setOpen(false);
            }}
          >
            <ListItemDecorator>
              <TextIncreaseIcon />
            </ListItemDecorator>
            Προσθήκη λέξεων
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              localStorage.removeItem("LoggedIn");
              navigate("/login");
              setOpen(false);
            }}
          >
            <ListItemDecorator>
              <LogoutIcon />
            </ListItemDecorator>
            Αποσύνδεση
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}
export default DrawerMenu;
