import { request } from "../Utils/Request";

const getLiveTransaction = async (payload) => {
  return await request(
    `/translation/getTranslation`,
    {
      from: payload.fromLanguage,
      to: payload.toLanguage,
      translation: payload.word,
    },
    null,
    "POST"
  );
};

const loginService = async (username, password) => {
  return await request(
    `/user/login`,
    {
      username,
      password,
    },
    null,
    "POST"
  );
};

const addNewWord = async (gr, rmgs, rmgn) => {
  return await request(
    `/translation/setTranslation`,
    {
      gr,
      rmgs,
      rmgn,
    },
    null,
    "POST"
  );
};

const getAllTranslations = async () => {
  return await request(`/translation/getAllTranslations`, null, "GET");
};

const updateTranslation = async (id, updatedGr, updatedRmgs, updatedRmgn) => {
  return await request(
    `/translation/updateTranslation`,
    {
      id,
      updatedGr,
      updatedRmgs,
      updatedRmgn,
    },
    null,
    "POST"
  );
};

const deleteTranslation = async (id) => {
  return await request(
    `/translation/deleteTranslation`,
    {
      id,
    },
    null,
    "POST"
  );
};

export {
  getLiveTransaction,
  loginService,
  addNewWord,
  getAllTranslations,
  updateTranslation,
  deleteTranslation,
};
