import { IconButton } from "@mui/joy";
import React from "react";
import Menu from "@mui/icons-material/Menu";

const MyMenu = ({ setOpen }) => {
  return (
    <IconButton
      id="toggle-mode"
      size="md"
      variant="soft"
      color="neutral"
      onClick={() => {
        setOpen(true);
        // localStorage.removeItem("LoggedIn");
        // navigate("/login");
      }}
      sx={{
        position: "absolute",
        zIndex: 999,
        top: "0.8rem",
        left: "1rem",
        borderRadius: "50%",
        // boxShadow: "sm",
      }}
    >
      <Menu />
    </IconButton>
  );
};
export default MyMenu;
