import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import theme from "../theme";
import { CssBaseline, Grid } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import { useNavigate } from "react-router-dom";
import InputFields from "./dashboardComponents/inputFields";
import Typography from "@mui/joy/Typography";
import MyTable from "../components/table/ΜyTable";
import { deleteTranslation, getAllTranslations } from "../services/services";
import { AlertDialogModal, MyModal } from "../components/Modal/MyModal";
import SnackbarComponent from "../components/Snackbar/SnackbarComponent";

const Dashboard = () => {
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("LoggedIn");
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [allTranslations, setAllTranslations] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [responseData, setResponseData] = useState({
    message: "",
    error: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
    fetchAllTranslations();
  }, [loggedIn]);

  const fetchAllTranslations = async () => {
    try {
      setIsLoading(true);
      const translations = await getAllTranslations();
      setAllTranslations(translations?.translations);
    } catch (e) {
      setIsSnackbarOpen(true);
      setResponseData({
        message: e.message,
        error: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditableTranslation = (translation, status) => {
    // Edit
    if (status === 1) {
      setOpenModal(true);
      setSelectedTranslation(translation);
    }
    //Delete
    if (status === 0) {
      setOpenDeleteAlert(true);
      setSelectedTranslation(translation);
    }
  };

  const deleteTranslationData = async () => {
    try {
      const result = await deleteTranslation(selectedTranslation.id);
      //Not found
      if (result.statusCode === 0) {
        setIsSnackbarOpen(true);
        setResponseData({
          message: result.message,
          error: true,
        });
      }
      //Success
      if (result.statusCode === 1) {
        setIsSnackbarOpen(true);
        setResponseData({
          message: result.message,
          error: false,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      await fetchAllTranslations();
    }
  };
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Box>
        <Grid
          container
          columns={4}
          spacing={{ sm: 0, md: 4 }}
          sx={{
            flexGrow: 1,
            padding: 1,
            maxWidth: "1300px",
            margin: "auto",
            minHeight: "90dvh",
          }}
          justifyContent="center"
        >
          <Grid xs={14} sm={7} md={1} justifyContent="center">
            <Typography sx={{ textAlign: "center" }} level={"title-lg"}>
              Προσθήκη λέξεων
            </Typography>
            <InputFields
              fetchAllTranslations={fetchAllTranslations}
              setIsOpen={setIsSnackbarOpen}
              setResponse={setResponseData}
            />
          </Grid>
          <Grid xs={14} sm={7} md={3} justifyContent="center">
            <MyTable
              allTranslations={allTranslations}
              handleEditableTranslation={handleEditableTranslation}
              isFetching={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
      <MyModal
        open={openModal}
        setOpen={setOpenModal}
        selectedTranslation={selectedTranslation}
        setOpenSnackbar={setIsSnackbarOpen}
        setResponseData={setResponseData}
        fetchAllTranslations={fetchAllTranslations}
      />
      <AlertDialogModal
        open={openDeleteAlert}
        setOpen={setOpenDeleteAlert}
        deleteTranslationData={deleteTranslationData}
      />
      <Footer />
      <SnackbarComponent
        open={isSnackbarOpen}
        setOpen={setIsSnackbarOpen}
        message={responseData.message}
        error={responseData.error}
      />
    </CssVarsProvider>
  );
};

export default Dashboard;
