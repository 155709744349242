import React from "react";
import Box from "@mui/joy/Box";
import Textarea from "@mui/joy/Textarea";
import { Grid, LinearProgress } from "@mui/joy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/joy/IconButton";
import VolumeUpTwoToneIcon from "@mui/icons-material/VolumeUpTwoTone";
const TranslationDisplayResult = ({ translatedWord, isLoading }) => {
  const speakText = () => {
    const text =
      translatedWord === "Μετάφραση"
        ? ""
        : translatedWord === "Δεν βρέθηκε η λέξη που ψάχνετε"
        ? ""
        : translatedWord ===
          "Υπήρξε κάποιο πρόβλημα, παρακαλώ δοκιμάστε ξανά αργότερα"
        ? ""
        : translatedWord;
    if ("speechSynthesis" in window) {
      const synthesis = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "el";
      synthesis.speak(utterance);
    } else {
      alert("Αυτή η λειτουργία δεν υποστηρίζετε από το browser σας");
    }
  };
  return (
    <>
      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "block",
            position: "relative",
            zIndex: 99999,
          }}
        >
          <LinearProgress
            sx={{
              position: "absolute",
              display: "contents",
            }}
            color="primary"
            variant={"plain"}
            determinate={false}
            value={30}
          />
        </Box>
      )}
      <Textarea
        placeholder={"Μετάφραση"}
        color={"neutral"}
        value={translatedWord}
        minRows={5}
        maxRows={5}
        variant={"soft"}
        readOnly
        endDecorator={
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={4}
              columns={2}
              sx={{ flexGrow: 1 }}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Grid xs={1}>
                <IconButton
                  onClick={speakText}
                  size={"md"}
                  color="neutral"
                  sx={{ float: "left" }}
                >
                  <VolumeUpTwoToneIcon />
                </IconButton>
              </Grid>
              <Grid xs={1} sx={{ float: "right" }}>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(translatedWord);
                  }}
                  size={"md"}
                  color="neutral"
                  sx={{ float: "right" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        }
        sx={{
          width: "100%",
          "--Textarea-focusedInset": "none",
          "&:focus-within": {
            borderColor: "none",
          },
        }}
      />
    </>
  );
};

export default TranslationDisplayResult;
