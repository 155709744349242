import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { getLiveTransaction } from "../../services/services";
import IconButton from "@mui/joy/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Sheet from "@mui/joy/Sheet";

const TranslationInputs = ({
  toLanguage,
  fromLanguage,
  setTranslatedWord,
  setIsLoading,
  text,
  setText,
}) => {
  useEffect(() => {
    if (text !== "" && fromLanguage.value !== "" && toLanguage.value !== "") {
      setIsLoading(true);
      const delayDebounceFn = setTimeout(() => {
        getData();
      }, 1000);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    } else {
      setTranslatedWord("Μετάφραση");
      setIsLoading(false);
    }
  }, [text, fromLanguage, toLanguage]);

  const getData = async () => {
    try {
      const data = await getLiveTransaction({
        fromLanguage: fromLanguage?.type,
        toLanguage: toLanguage?.type,
        word: text,
      });
      if (Object.keys(data).length) {
        setTranslatedWord(
          data?.translated ? data?.translated : "Δεν βρέθηκε η λέξη που ψάχνετε"
        );
      } else {
        setTranslatedWord("Δεν βρέθηκε η λέξη που ψάχνετε");
      }
    } catch (e) {
      console.log(e);
      setTranslatedWord(
        "Υπήρξε κάποιο πρόβλημα, παρακαλώ δοκιμάστε ξανά αργότερα"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Textarea
        placeholder={""}
        value={text}
        onChange={(event) => setText(event.target.value)}
        minRows={5}
        maxRows={5}
        variant={"outlined"}
        startDecorator={
          <Sheet sx={{ position: "absolute", right: 0 }}>
            {text && (
              <IconButton
                onClick={() => {
                  setText("");
                }}
                size={"sm"}
                color="neutral"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Sheet>
        }
        endDecorator={
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              flex: 1,
              // marginBottom: text ? 0.6 : 1,
            }}
          >
            <Typography level="body-xs" sx={{ ml: "auto" }}>
              {text.length} χαρακτήρες
            </Typography>
          </Box>
        }
        sx={{
          height: 180,
          paddingRight: 2,
          width: "100%",
          "--Textarea-focusedInset": "none",
          "&:focus-within": {
            borderColor: "none",
          },
        }}
      />
    </>
  );
};

export default TranslationInputs;
