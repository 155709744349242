import { LinearProgress } from "@mui/joy";
import Box from "@mui/joy/Box";

const Loader = () => {
  return (
    <Box>
      <LinearProgress />
    </Box>
  );
};

export default Loader;
