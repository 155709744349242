import React from "react";
import Sheet from "@mui/joy/Sheet";
import { Box, Typography } from "@mui/joy";
import ColorSchemeToggle from "./ColorSchemeToggle";
import logo from "./img/rmg-logo.svg";
import MyMenu from "./MyMenu";
import { useNavigate } from "react-router-dom";
import DrawerMenu from "../DrawerMenu/DrawerMenu";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const loggedIn = localStorage.getItem("LoggedIn");
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          boxShadow: "sm",
          marginBottom: 2,
        }}
      >
        <Sheet
          variant={"soft"}
          sx={{
            padding: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {loggedIn && <MyMenu setOpen={setOpen} />}
          <Sheet
            variant={"soft"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img src={logo} width={50} height={50} />
            <Typography level="h4" color="primary" variant="plain">
              RMG - Translator
            </Typography>
          </Sheet>

          <ColorSchemeToggle />
        </Sheet>
      </Box>
      <DrawerMenu open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
